import { Inject, Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

type PreloaderClosedEvent = { elapsed: number; message: string }

@Injectable({ providedIn: 'root' })
export class PreloaderService {
	
	private i18n = inject(TranslateService)

	private started = new Date().getTime()
	private preClosingMessage: string;
	private closeBtn = null
	private closed = false
	private shown = false

	constructor(@Inject(DOCUMENT) private document: Document) {
		const loader = this.document.querySelector('#app-loader') as HTMLElement
		this.shown = loader && loader.style.display !== 'none'
	}

	hasBeenShown(): boolean {
		return this.shown
	}

	showTips(tips: string[]): void {
		const next = () => {
			if (this.closed) return;
			this.showCloseButton()
			const footerText = this.document.querySelector('#al-footer-text');
			if (!footerText) return;
			footerText.innerHTML = tips[Math.floor(Math.random() * tips.length)].replace(/\n/g, `<br>`);
			setInterval(next, 2_000)
		}
		next()
	}

	withPreClosingMessage(message: string): PreloaderService {
		this.preClosingMessage = message
        return this
	}

	closePreloaderWhenPreloadersFinished(min = 2_000, max = 20_000): Promise<PreloaderClosedEvent> {
		return new Promise(resolve => {
			if (!this.hasBeenShown()) {
				return resolve(this.closePreloader({ elapsed: 0, message: `not shown` }));
			}

			const closePreloaderWhenReady = () => {
				const elapsed = new Date().getTime() - this.started

				if (elapsed >= 2_000) {
					this.showCloseButton(resolve)
				}

				if (!this.someLoadersPresent() && elapsed > min)
					return resolve(this.closePreloader({ elapsed, message: 'after all loaders finished' }))

				if (elapsed > max)
					return resolve(this.closePreloader({ elapsed, message: `after ${elapsed}ms` }))
		
				setTimeout(closePreloaderWhenReady, 1000);
			}	

			closePreloaderWhenReady()
		})
	}

	private showCloseButton(resolve: CallableFunction = () => null) {
		if (this.closeBtn) return;
		this.closeBtn = this.document.querySelector('#al-footer-close')
		if (!this.closeBtn) return;
		this.closeBtn.classList.remove('d-none')
		this.closeBtn.innerHTML = this.i18n.instant('Actions.Close')
		this.closeBtn.addEventListener('click', () => resolve(this.closePreloader({
			elapsed: new Date().getTime() - this.started,
			message: 'by user'
		})))
	}

	private closePreloader(reason: PreloaderClosedEvent) {
		this.closed = true
		console.debug(`[init] preloader hiding: ${reason.message}`)

		const footer = this.document.querySelector('#al-footer') as HTMLDivElement
		const animation = this.document.querySelector('#app-animation')
		const welcome = this.document.querySelector('#app-welcome')
		const loader = this.document.querySelector('#app-loader')
		if (!loader) return;

		animation.classList.add('al-hidden')

		if (this.preClosingMessage) {
			footer.style.visibility = 'hidden'
			welcome.innerHTML = `<h1>${this.preClosingMessage}</h1>`
			loader.classList.add('al-hidden-with-message')

			setTimeout(() => this.removeLoader(reason), 2000)

		} else {
			this.removeLoader(reason)
		}

		return reason;
	}

	removeLoader(reason: PreloaderClosedEvent) {
		const loader = this.document.querySelector('#app-loader')
		if (!loader) return;

		setTimeout(() => {
			console.debug(`[init] preloader removed after ${new Date().getTime() - this.started}ms`)
			loader.remove()
		}, 300)

		return reason;
	}

	private someLoadersPresent() {
		return this.document.querySelector('loader-spinner .loader')
	}
}