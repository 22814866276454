import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IndicatorsService, UserReportsService } from 'apps/federation/src/app/admin-module/services';

import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class AdminStoreEffects {

	constructor(
		private actions$: Actions,
		private indicatorService: IndicatorsService,
		private userReportsService: UserReportsService,
	) {
	}

	loadAgeingBalanceRanges$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAgeingBalanceRangesRequest),
		switchMap(_ => this.indicatorService.getAgeingBalanceRanges().pipe(
			map(entity => featureActions.LoadAgeingBalanceRangesSuccess({ entity })),
			catchError(error => of(featureActions.LoadAgeingBalanceRangesFailure({ error })))
		))
	));

	loadDefaultAgeingBalanceRanges$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAgeingBalanceRangesDefaultRequest),
		switchMap(_ => this.indicatorService.getAgeingBalanceRangesDefault().pipe(
			map(entity => featureActions.LoadAgeingBalanceRangesDefaultSuccess({ entity })),
			catchError(error => of(featureActions.LoadAgeingBalanceRangesDefaultFailure({ error })))
		))
	));

	updateAgeingBalanceRanges$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAgeingBalanceRangesRequest),
		switchMap(action => this.indicatorService.postAgeingBalanceRanges(action.entity).pipe(
			map(_ => featureActions.UpdateAgeingBalanceRangesSuccess({ entity: action.entity })),
			catchError(error => of(featureActions.UpdateAgeingBalanceRangesFailure({ error })))
		))
	));

	loadAgeingBalanceRangesConfig$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAgeingBalanceRangesConfigRequest),
		switchMap(_ => this.indicatorService.getAgeingBalanceRangesConfig().pipe(
			map(entity => featureActions.LoadAgeingBalanceRangesConfigSuccess({ entity })),
			catchError(error => of(featureActions.LoadAgeingBalanceRangesConfigFailure({ error })))
		))
	));

	loadUserReportSettingsConfig$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadUserReportsSettingsRequest),
		switchMap(_ => this.userReportsService.getUserReportsSettings().pipe(
			map(entity => featureActions.LoadUserReportsSettingsSuccess({ entity })),
			catchError(error => of(featureActions.LoadUserReportsSettingsFailure({ error })))
		))
	));

	updateUserReportSettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateUserReportsSettingsRequest),
		switchMap(action => this.userReportsService.updateUserReportsSettings(action.entity).pipe(
			map(_ => featureActions.UpdateUserReportsSettingsSuccess({ entity: action.entity })),
			catchError(error => of(featureActions.UpdateUserReportsSettingsFailure({ error })))
		))
	));
}
