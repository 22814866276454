
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { accountingReducer } from './reducer';
import { AccountingStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, accountingReducer),
		EffectsModule.forFeature([AccountingStoreEffects])
	]
})

export class AccountingStoreModule { }
