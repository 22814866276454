import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ActionType, UnsubscriberClass } from '@aston/foundation';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';
import { IAction } from '@aston/foundation';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: '[layout-menu]',
	templateUrl: './menu.component.html',
	standalone: true,
	imports: [RouterModule, TranslateModule]
})
export class MenuComponent extends UnsubscriberClass implements AfterViewInit {

	routes = {
		homePage:																		RoutesDefinitions.getHomeFullPath(),
		overviewPage:																RoutesDefinitions.getOverviewFullPath(),
		opOverviewPage:															RoutesDefinitions.getOverviewFullPath(RoutesDefinitions.DashboardsPageOperationalRoutePath),
		fiOverviewPage:															RoutesDefinitions.getOverviewFullPath(RoutesDefinitions.DashboardsPageFinancialRoutePath),
		superCustomersList: 												RoutesDefinitions.getSuperCustomersListFullPath(),
		accountingList: 														RoutesDefinitions.getAccountingListFullPath(),
		adminToolsPage: 														RoutesDefinitions.getAdminToolsPageRouteFullPath(),
		adminToolsReportsPage: 					  				  RoutesDefinitions.getAdminToolsReportsRouteFullPath(),
		adminToolsIndicatorsPage: 									RoutesDefinitions.getAdminToolsIndicatorsRouteFullPath(),
		adminToolsWorkflowSchedulingInstancesList: 	RoutesDefinitions.getAdminToolsWorkflowSchedulingInstancesListRouteFullPath(),
	};

	@Output() action: EventEmitter<IAction> = new EventEmitter();
	@Input() hasManualImportClearanceLevel: boolean;
	@Input() hasImportHistoryClearanceLevel: boolean;
	@Input() hasLetteringClearanceLevel: boolean;
	@Input() hasPaymentOrderClearanceLevel: boolean;
	@Input() hasAdministrationToolsClearanceLevel: boolean;
	@Input() hasSuperAdministrationClearanceLevel: boolean;
	@Input() hasReadOnlyAccessToAllSuperDebtorsClearanceLevel: boolean;
	@Input() hasDunningClearanceLevel: boolean;
	@Input() isSupport: boolean;

	@ViewChild('menulinks') menu: ElementRef<any>;

	constructor(protected router: Router) {
		super();
	}

	ngAfterViewInit() {
		// This can be used to sync expanded menu with the navigation
		// In this case, the HostListener should be removed.
		// this.syncExpandedMenuWithNavigation();

		// This can be used to expand the menu of the first page only
		this.syncExpandedMenuWithCurrentPage();
	}

	syncExpandedMenuWithCurrentPage() {
		const link = this.menu.nativeElement.querySelector(`a[href*="${this.router.url}"]`);
		this.collapseOtherMenus(link);
	}

	syncExpandedMenuWithNavigation() {
		this.router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			map(e => (e as NavigationEnd).urlAfterRedirects),
			startWith(this.router.url),
			takeUntil(this.destroySubscriptions$)
		).subscribe(url => {
			const link = this.menu.nativeElement.querySelector(`a[href~="${url}"]`);
			this.collapseOtherMenus(link);
		})
	}

	@HostListener('click', ['$event.target'])
	collapseOtherMenus(btn: HTMLDivElement) {
		const fromTitle = btn && btn.previousElementSibling
			&& btn.previousElementSibling.classList.contains('menu-icon');

		let newParent = btn;
		while (btn && newParent && !(newParent.parentNode as HTMLDivElement).classList.contains('menu-links')) {
			newParent = newParent.parentNode as HTMLDivElement;
		}

		[...this.menu.nativeElement.children]
			.filter(e => e !== newParent)
			.forEach(e => e.classList.add('menu-collapsed'));

		if (newParent) {
			if (fromTitle)
				newParent.classList.toggle('menu-collapsed');
			else
				newParent.classList.remove('menu-collapsed');
		}
	}

	onToggle() {
		this.action.emit({ type: ActionType.TOGGLE });
	}

	specialActiveCases(): boolean {
		return false
			;
	}
}
