import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { WorkflowSchedulingService } from 'apps/federation/src/app/admin-module/services';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, debounceTime, delay, filter } from 'rxjs/operators';
import { CorrelationParams, aggregate } from 'apps/federation/src/app/root-store/models/correlated-actions.model';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';

import { AppStoreActions } from '../app-store';

import * as featureSelectors from './selectors';
import * as featureActions from './actions';


@Injectable({providedIn: 'root'})
export class WorkflowSchedulingStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private workflowSchedulingService: WorkflowSchedulingService) { }

	loadInstancesListRequestEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadInstancesListRequest),
		withLatestFrom(this.store.select(featureSelectors.selectInstancesSlice)),
		debounceTime(500),
		switchMap(([_, instancesSlice]) => {
			const { filters, paging } = instancesSlice;
			return this.workflowSchedulingService.getInstances({ ...paging, filters }).pipe(
				map(list => featureActions.LoadInstancesListSuccess({ list })),
				catchError(error =>
					of(featureActions.LoadInstancesListFailure({ error }))
				)
			);
		})
	));

	updateFilterEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddInstancesListFilters, featureActions.RemoveInstancesListFilters, featureActions.UpdateInstancesListSettings),
		map(_ => featureActions.LoadInstancesListRequest())
	));

	loadInstancesListSuccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadInstancesListSuccess),
		withLatestFrom(this.store.select(featureSelectors.selectInstancesList)),
		filter(([_, current]) => !current?.items?.length), // Scroll to top only the first time
		map(_ => AppStoreActions.StartScrollToTop())
	));

	loadConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadConfigRequest),
		switchMap(_ =>
			this.workflowSchedulingService.getConfig().pipe(
				map(entity => featureActions.LoadConfigSuccess({ entity })),
				catchError(error => of(featureActions.LoadConfigFailure({ error })))
			)
		)
	));

	updateConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateConfigRequest),
		switchMap(action =>
			this.workflowSchedulingService.saveConfig(action.entity).pipe(
				map(_ => featureActions.UpdateConfigSuccess({ entity: action.entity, correlationParams: action.correlationParams })),
				catchError(error => of(featureActions.UpdateConfigFailure({ error })))
			)
		)
	));

	StartConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartConfigRequest),
		switchMap(_ =>
			this.workflowSchedulingService.startConfig().pipe(
				map(_ => featureActions.UpdateConfigSuccess({ entity : null })),
				catchError(error => of(featureActions.UpdateConfigFailure({ error })))
			)
		)
	));

	DirectStartConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DirectStartConfigRequest),
		switchMap(action =>
			this.workflowSchedulingService.directStartConfig(action.param).pipe(
				switchMap(workflowInstanceId => [
					AppStoreActions.Navigate({ to: [RoutesDefinitions.getAdminToolsWorkflowSchedulingInstanceDetailRouteFullPath(workflowInstanceId)] }),
					featureActions.UpdateConfigSuccess({ entity : null })
				]),
				catchError(error => of(featureActions.UpdateConfigFailure({ error })))
			)
		)
	));

	UpdateAndStartConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAndStartConfigRequest),
		map(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return featureActions.UpdateConfigRequest(action.entity, correlationParams);
		})
	));

	AggregatedUpdateAndStartConfigRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAndStartConfigRequest),
		aggregate(
			this.actions$.pipe(ofType(featureActions.UpdateConfigSuccess)),
			this.actions$.pipe(ofType(featureActions.UpdateConfigFailure)),
		),
		map(_ => featureActions.StartConfigRequest())
	));

	loadInstanceDetailRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadInstanceDetailRequest),
		switchMap(action =>
			this.workflowSchedulingService.getInstance(action.id).pipe(
				map(entity => featureActions.LoadInstanceDetailSuccess({ entity })),
				catchError(error => of(featureActions.LoadInstanceDetailFailure({ error })))
			)
		)
	));

	retryOperationRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.RetryOperationRequest),
		switchMap(action => {
			return this.workflowSchedulingService.retryOperation(action.operationId, action.skipChildren).pipe(
				delay(1000),
				map(_ => featureActions.LoadInstanceDetailRequest(action.instanceId, action.correlationParams)),
				catchError(error => of(featureActions.UpdateConfigFailure({ error })))
			)
		})
	));
}
