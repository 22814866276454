import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, switchMap, withLatestFrom, debounceTime, tap } from 'rxjs/operators';
import { AccountingDocumentsService, AccountingListFilterSpecService } from 'apps/federation/src/app/accounting-module/services';
import { TranslateService } from '@ngx-translate/core';
import { removeFilterBySpec } from '@aston/foundation';
import { saveAs } from 'file-saver';

import { catchWithAppError as catchError, AppStoreActions } from '../app-store';
import * as appActions from '../app-store/actions';

import { IAccountingState } from './state';
import * as featureSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class AccountingStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		protected modalService: NgbModal,
		protected translateService: TranslateService,
		private accountingService: AccountingDocumentsService,
		private accountingListFilterSpecService: AccountingListFilterSpecService,
	) { }

	onDebug$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), { dispatch: false });


	loadAccountingEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		debounceTime(500),
		switchMap(([_, state]) => {
			const { filters, paging } = this.cleanFilters(state);
			return this.accountingService.getAccountingDocuments({ ...paging, filters }).pipe(
				map(list => featureActions.LoadAccountingSuccess({ list })),
				catchError(error => of(featureActions.LoadAccountingFailure({ error })))
			);
		})
	));

	loadSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingSuccess),
		map(_ => appActions.StartScrollToTop())
	));

	updateAccountingSettingsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAccountingSettings, featureActions.SetShowCompleted),
		map(_ => featureActions.LoadAccountingRequest())
	));

	updateAccountingFilterEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddAccountingFilters, featureActions.RemoveAccountingFilters),
		map(_ => featureActions.LoadAccountingRequest())
	));

	exportFactoringEventsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportAccountingRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([action, state]) => {
			const { filters, paging } = this.cleanFilters(state);
			return this.accountingService.exportAccountingList({ ...paging, filters }, false).pipe(
				map(response => {
					saveAs(response.blob, response.fileName);
					return featureActions.ExportAccountingSuccess({ correlationParams: action.correlationParams });
				}),
				catchError(error => of(
					featureActions.ExportAccountingFailure({ error }),
					AppStoreActions.ToastError('Errors.RetryableError')
				))
			);
		})
	));

	cleanFilters(state: IAccountingState) {
		const {
			excludeCompletedDocuments,
		} = this.accountingListFilterSpecService;

		const filters = [...state.list.filters];
		const paging = state.list.paging;

		removeFilterBySpec(filters, excludeCompletedDocuments.spec);
		if (!state.showCompleted) {
			filters.push(excludeCompletedDocuments);
		}

		return { filters, paging };
	}
}
