import { NotFoundPageComponent } from '@aston/foundation';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import * as RoutesDefinitions from './routes-definitions';
import { MasterLayoutComponent } from './shared-module/layouts/master-layout/master-layout.component';
import { LoginPageComponent } from './authentication-module/pages/login-page/login-page.component';
import { LoginCallbackPageComponent } from './authentication-module/pages/callback-page/callback-page.component';
import { AuthenticationLayoutComponent } from './authentication-module/layouts/authentication-layout/authentication-layout.component';
import { AuthenticationGuard, AnonymousGuard } from './authentication-module/guards';
import { AuthenticationIssuePageComponent } from './authentication-module/pages/authent-issue-page/authentication-issue-page.component';


const loginRoutePath = RoutesDefinitions.AuthenticationRoutePath + '/' + RoutesDefinitions.LoginRoutePath;


const routes: Routes = [
	// Redirects to login page
	{path: '', pathMatch: 'prefix', redirectTo: loginRoutePath},

	// Authentication process pages
	{
		path: RoutesDefinitions.AuthenticationRoutePath, component: AuthenticationLayoutComponent,
		children: [
			{
				path: RoutesDefinitions.LoginRoutePath, component: LoginPageComponent, canActivate: [AnonymousGuard],
				data: {titleTranslationKey: 'Login', layoutName: 'login'}
			},
			{
				path: RoutesDefinitions.AuthenticationIssueRoutePath, component: AuthenticationIssuePageComponent,
				data: {titleTranslationKey: 'Login', layoutName: 'authentication-issue'}
			},

			{
				path: RoutesDefinitions.LoginCallbackRoutePath, component: LoginCallbackPageComponent,
				data: {titleTranslationKey: 'Login', layoutName: 'login'}
			},
		]
	},

	// Authenticated pages
	{
		path: '', component: MasterLayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			// Service pages
			{path: 'welcome', pathMatch: 'prefix', redirectTo: RoutesDefinitions.PlatformHomePageRoutePath},
			{
				path: 'not-found', component: NotFoundPageComponent,
				data: {titleTranslationKey: 'NotFound'},
			},
			{
				path: RoutesDefinitions.PlatformHomePageRoutePath,
				data: { titleTranslationKey: 'Home', },
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: () => RoutesDefinitions.DashboardsPageFinancialRoutePath,
					},
					{
						path: '',
						pathMatch: 'prefix',
						loadChildren: () => import('./home-module/home-routing.module'),
					},
				]
			},
			{
				path: RoutesDefinitions.SuperCustomersListRoutePath,
				data: {
					titleTranslationKey: 'SuperDebtors',
					layoutName: 'super-debtors-list'
				},
				loadChildren: () => import('./customers-module/customers.module').then(m => m.CustomersModule)
			},
			{
				path: RoutesDefinitions.AccountingDocsListRoutePath,
				data: {
					titleTranslationKey: 'Accounting',
					layoutName: 'accounting-list'
				},
				loadChildren: () => import('./accounting-module/accounting.module').then(m => m.AccountingModule)
			},
			{
				path: RoutesDefinitions.AdminToolsPath,
				data: {
					titleTranslationKey: 'AdminTools',
					layoutName: 'admin-tools'
				},
				loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule)
			}
		]
	},

	// Default redirection
	{path: '**', redirectTo: loginRoutePath}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,
		{enableTracing: false} // <-- debugging purposes only
	)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
