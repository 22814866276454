

import { AccountingListSortProp } from 'apps/federation/src/app/accounting-module/enums';
import { IAccountingItemList, IAccountingListFilter } from 'apps/federation/src/app/accounting-module/models';
import { AppConstants } from 'apps/federation/src/app/app.constants';

import { createListSlice, IStoreListStateSlice } from '../functions';

import { FeatureName } from './feature';

export interface IAccountingState {
	list: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>;
	showCompleted: boolean;
}

export const initialState: IAccountingState = {
	list: createListSlice({ prop: AccountingListSortProp.DueDate, isDesc: false }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.list`),
	showCompleted: false,
};
