
import { Injectable } from '@angular/core';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { mapToUserReportSetting } from '../functions/mappers.functions';
import { IUserReportSetting } from '../models';

@Injectable({
	providedIn: 'root'
})
export class UserReportsService {

	constructor(private http: HttpClient, private store: Store) {
	}

	getUserReportsSettings() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IUserReportSetting[]>(`${config.apiUrl}/api/accounts/me/reports`)),
			map(models => models.map(m => mapToUserReportSetting(m)))
		);
	}

	updateUserReportsSettings(model: IUserReportSetting[]) {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/accounts/me/reports`, model)),
		);
	}
}
