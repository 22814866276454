import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PageLoaderService, UnsubscriberClass } from '@aston/foundation';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';

import { AuthenticationService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class AnonymousGuard extends UnsubscriberClass implements CanActivate {

	constructor(private authenticationService: AuthenticationService, private pageLoaderService: PageLoaderService, private router: Router) {
		super();
	}

	canActivate(): Observable<boolean>|boolean {
		return this.isAuthenticated();
	}

	canLoad(): Observable<boolean>|boolean {
		return this.isAuthenticated();
	}

	private isAuthenticated(): Observable<boolean>|boolean {
		this.pageLoaderService.start();

		return this.authenticationService.getIsAuthenticated().pipe(
			takeUntil(this.destroySubscriptions$),
			map(isAuth => {
				this.pageLoaderService.end();

				if (isAuth) {
					this.router.navigate([RoutesDefinitions.getHomeFullPath()])
				}

				return !isAuth;
			})
		);
	}
}
