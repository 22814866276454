import { createReducer, on } from '@ngrx/store';

import { withListReducer } from '../functions';

import { initialState, IAccountingState } from './state';
import * as featureActions from './actions';



export const accountingReducer = createReducer<IAccountingState>(
	initialState,

	...withListReducer<IAccountingState>('list', featureActions.accountingListActions),

	on(featureActions.SetShowCompleted, (state, action) => ({
		...state,
		showCompleted: action.showCompleted,
		allSelected: false,
		selectedIds: []
	})),
);
