import { Injectable } from '@angular/core';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class WorkersService {

	constructor(private http: HttpClient, private store: Store) {
	}

	importWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Imports/requestAutomaticImport/manual`, {})),
		);
	}

	letteringWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Lettering/requestComputing/manual`, {})),
		);
	}

	computedValuesWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/ComputedValues/requestComputing/manual`, {})),
		);
	}

	superDebtorDunningWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/requestComputing/manual`, {})),
		);
	}

	sellerDunningWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/seller/requestComputing/manual`, {})),
		);
	}

	preventiveDunningWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/preventive/requestComputing/manual`, {})),
		);
	}

	dunningGroupsWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/groups/requestAffectations/manual`, {})),
		);
	}

	automaticDunningActionCompletionWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/Actions/requestAutomaticDunningActionCompletion/manual`, {})),
		);
	}

	superDebtorGreetingWorker() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Dunning/Actions/requestSuperDebtorGreeting/manual`, {})),
		);
	}

}
