import { Injectable } from '@angular/core';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AgeingBalanceRangeModelApi } from '../models/api';
import { mapToAgeingBalanceRangeApiModel, mapToAgeingBalanceRangeModel } from '../functions/mappers.functions';
import { AgeingBalanceRangeConfigModel, AgeingBalanceRangeModel } from '../models/ageing-balance-range.model';

@Injectable({
	providedIn: 'root'
})
export class IndicatorsService {

	constructor(private http: HttpClient, private store: Store) {
	}

	getAgeingBalanceRanges() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<AgeingBalanceRangeModelApi[]>(`${config.apiUrl}/api/AgeingBalanceRanges`)
			.pipe(
				map(apiModels => {
					return apiModels.map(item => mapToAgeingBalanceRangeModel(item));
				}),
			)),
		);
	}

	postAgeingBalanceRanges(model: AgeingBalanceRangeModel[]) {
		const params = model.map(item => mapToAgeingBalanceRangeApiModel(item))
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/AgeingBalanceRanges`, params)),
		);
	}

	getAgeingBalanceRangesConfig() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<AgeingBalanceRangeConfigModel>(`${config.apiUrl}/api/AgeingBalanceRanges/Config`)),
		);
	}

	getAgeingBalanceRangesDefault() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<AgeingBalanceRangeModelApi[]>(`${config.apiUrl}/api/AgeingBalanceRanges/default`)
			.pipe(
				map(apiModels => {
					return apiModels.map(item => mapToAgeingBalanceRangeModel(item));
				}),
			)),
		);
	}

}
