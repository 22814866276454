import { createReducer } from '@ngrx/store';
import { withEntityReducer } from '@aston/foundation';

import * as featureActions from './actions';
import { initialState, IAdminState } from './state';



export const adminReducer = createReducer<IAdminState>(
	initialState,

	...withEntityReducer<IAdminState>('userReportSettings', featureActions.userReportsActions),
	...withEntityReducer<IAdminState>('ageingBalanceRanges', featureActions.ageingBalanceRangesActions),
	...withEntityReducer<IAdminState>('ageingBalanceRangesDefault', featureActions.ageingBalanceRangesDefaultActions),
	...withEntityReducer<IAdminState>('ageingBalanceRangesConfig', featureActions.ageingBalanceRangesConfigActions),
);
