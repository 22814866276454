export enum ReportType {
    None = 'None',
    AgeingBalance = 'AgeingBalance',
    AgeingBalanceDetailed = 'AgeingBalanceDetailed',
    EstimatedCashing = 'EstimatedCashing',
    TeamFollowUp = 'TeamFollowUp',
    DunningActions = 'DunningActions',
    Dso = 'Dso',
    OutstandingDunningLevel = 'OutstandingDunningLevel',
    TotalRevenue = 'TotalRevenue',
    ClientTopLitigation = 'ClientTopLitigation',
    WorldMap = 'WorldMap',
    Synthesis = 'Synthesis',
    AverageOverdue = 'AverageOverdue',
    AverageCompletion = 'AverageCompletion'
}
