import { Injectable } from '@angular/core';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { getListApiHttpParam, mapToListModel, IListState, WorkflowSchedulingInstancesListSortProp, IWorkflowSchedulingConfig, IWorkflowSchedulingInstanceDetail, IWorkflowSchedulingInstanceItem, IWorkflowSchedulingInstancesList } from '@aston/foundation';
import { Observable } from 'rxjs';

import { mapToWorkerSchedulingInstanceDetail, mapToWorkerSchedulingInstanceItem } from '../functions/mappers.functions';
import { IWorkflowSchedulingInstancesListApi, IWorkflowSchedulingInstanceItemApi, IWorkflowSchedulingInstanceDetailApi } from '../models/api';

@Injectable({
	providedIn: 'root'
})
export class WorkflowSchedulingService {
	constructor(private http: HttpClient, private store: Store) {
	}

	getInstances(params: IListState<WorkflowSchedulingInstancesListSortProp>): Observable<IWorkflowSchedulingInstancesList> {
		const httpParams = getListApiHttpParam(params);

		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IWorkflowSchedulingInstancesListApi>(`${config.apiUrl}/api/Workflows/instances`, { params: httpParams })
			.pipe(
				map(apiModel => {
					return <IWorkflowSchedulingInstancesList>mapToListModel<IWorkflowSchedulingInstanceItemApi, IWorkflowSchedulingInstanceItem>(apiModel, mapToWorkerSchedulingInstanceItem);
				}),
			))
		);
	}

	getInstance(id: number): Observable<IWorkflowSchedulingInstanceDetail> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IWorkflowSchedulingInstanceDetailApi>(`${config.apiUrl}/api/Workflows/instances/${id}`)
			.pipe(map(apiModel => mapToWorkerSchedulingInstanceDetail(apiModel)))
		));
	}

	getConfig(): Observable<IWorkflowSchedulingConfig> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IWorkflowSchedulingConfig>(`${config.apiUrl}/api/Workflows/Definitions/current`)
		));
	}

	saveConfig(model: IWorkflowSchedulingConfig) {
		const params = { content: JSON.stringify(model) };

		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Workflows/definitions`, params)
		));
	}

	startConfig() {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Workflows/definitions/current/start`, {})
		));
	}

	directStartConfig(model: IWorkflowSchedulingConfig): Observable<number> {
		const params = { content: JSON.stringify(model) };

		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post<number>(`${config.apiUrl}/api/Workflows/definitions/start`, params)
		));
	}

	retryOperation(id: number, skipChildren: boolean) {
		const headers = new HttpHeaders().append('Content-Type', 'application/json');
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/Workflows/operations/${id}/retry`, skipChildren, {headers})
		));
	}
}
