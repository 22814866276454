export enum WorkflowSchedulingExecutionStatus {
	None = 'None',
	// Not yet run.
	WaitingToBeRun = 'WaitingToBeRun',
	// The workflow is being processed.
	Running = 'Running',
	// All nodes are failed.
	Failure = 'Failure',
	// All nodes are successful.
	Success = 'Success',
	// All nodes does not have the same status.
	Inconclusive = 'Inconclusive'
}

export enum WorkflowSchedulingWorkflowType {
	Global = 'Global',
	Manual = 'Manual',
}

export enum WorkflowSchedulingOperationExecutionBehavior {
	Immediate = 'Immediate',
	AfterAllOthersChildren = 'AfterAllOthersChildren',
}

export enum WorkflowSchedulingOperationExecutionStatus {
	None = 'None',
	WaitingToBeRun = 'WaitingToBeRun',
	Running = 'Running',
	Failure = 'Failure',
	Success = 'Success',
	NotReached = 'NotReached',
	Skipped = 'Skipped',
}

export enum WorkflowSchedulingOperationIsolationLevel {
	Serialized = 'Serialized',
	Parallel = 'Parallel'
}
