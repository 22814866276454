import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createListSliceSelectors } from '../functions';

import { IAccountingState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, IAccountingState> = createFeatureSelector<IAccountingState>(FeatureName);

export const {
	selectList: selectAccounting,
	selectFilters: selectAccountingFilters,
	selectError: selectAccountingError,
	selectIsLoading: selectAccountingIsLoading,
	selectIsExporting: selectAccountingIsExporting,
	selectPaging: selectAccountingPaging,
} = createListSliceSelectors(createSelector(selectState, state => state.list));

const getShowCompleted = (state: IAccountingState): boolean => state.showCompleted;
export const selectShowCompleted: MemoizedSelector<IAccountingState, boolean> = createSelector(selectState, getShowCompleted);
