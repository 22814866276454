
import { createEntitySliceSelectors } from '@aston/foundation';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ReportType } from 'apps/federation/src/app/admin-module/enums';
import { IUserReportSetting, IUserReportSettingsByCategory } from 'apps/federation/src/app/admin-module/models';

import { FeatureName } from './feature';
import { IAdminState } from './state';

export const selectState: MemoizedSelector<object, IAdminState> = createFeatureSelector<IAdminState>(FeatureName);

export const {
	selectEntity: selectAgeingBalanceRanges,
	selectIsLoading: selectAgeingBalanceRangesIsLoading,
	selectError: selectAgeingBalanceRangesError
} = createEntitySliceSelectors(createSelector(selectState, state => state.ageingBalanceRanges));

export const {
	selectEntity: selectAgeingBalanceRangesDefault,
	selectIsLoading: selectAgeingBalanceRangesDefaultIsLoading,
	selectError: selectAgeingBalanceRangesDefaultError
} = createEntitySliceSelectors(createSelector(selectState, state => state.ageingBalanceRangesDefault));

export const {
	selectEntity: selectAgeingBalanceRangesConfig,
	selectIsLoading: selectAgeingBalanceRangesConfigIsLoading,
	selectError: selectAgeingBalanceRangesConfigError
} = createEntitySliceSelectors(createSelector(selectState, state => state.ageingBalanceRangesConfig));

export const {
	selectEntity: selectUserReportsSettings,
	selectIsLoading: selectUserReportsSettingsIsLoading,
	selectError: selectUserReportsSettingsError
} = createEntitySliceSelectors(createSelector(selectState, state => state.userReportSettings));

const getUserReportsSettingsByCategory = (reportsSettings: IUserReportSetting[]): IUserReportSettingsByCategory[] => {
	const rt = (reportType: ReportType) => reportsSettings.find(rs => rs.type === reportType);

	if (!reportsSettings.length) return;

	return [
		{
			name: 'Menu.HomePage.Item1',
			items: [rt(ReportType.OutstandingDunningLevel), rt(ReportType.AgeingBalance), rt(ReportType.AgeingBalanceDetailed), rt(ReportType.ClientTopLitigation)],
		},
		{
			name: 'Menu.HomePage.Item2',
			items: [rt(ReportType.WorldMap), rt(ReportType.TotalRevenue), rt(ReportType.Dso), rt(ReportType.EstimatedCashing), rt(ReportType.AverageOverdue), rt(ReportType.AverageCompletion)],
		},
		{
			name: 'AdminTools.UserReports.Category.Summary',
			items: [rt(ReportType.Synthesis)],
		},
	];
}
export const selectUserReportsSettingsByCategory = createSelector(selectUserReportsSettings, getUserReportsSettingsByCategory);
