import { Injectable } from '@angular/core';
import { IFilterSpec, IFilterFieldSpec, stringOperatorsSpecs, IFilterOperator, dateOperatorsSpecs, alphanumExtendedWithSpace, WorkflowSchedulingExecutionStatus, WorkflowSchedulingWorkflowType } from '@aston/foundation';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class WorkflowInstancesFilterSpecService {

	typeValues = [
		{ value: WorkflowSchedulingWorkflowType.Global, label: 'AdminTools.WorkflowScheduling.Types.Global' },
		{ value: WorkflowSchedulingWorkflowType.Manual, label: 'AdminTools.WorkflowScheduling.Types.Manual' },
	];

	statusValues = Object.values(WorkflowSchedulingExecutionStatus)
		.filter(value => value !== WorkflowSchedulingExecutionStatus.None)
		.map(value => ({ value, label: `AdminTools.WorkflowScheduling.Status.${value}` }));

	workflowDefinitionNameFieldFilterSpec: IFilterFieldSpec = {
		field: { label: 'DataList.Columns.Name', value: 'WorkflowDefinitionName' },
		operatorSpecs: stringOperatorsSpecs,
		validation: alphanumExtendedWithSpace
	};

	creationDateFieldFilterSpec: IFilterFieldSpec = {
		field: { label: 'Forms.CommonLabels.CreationDate', value: 'CreationDate' },
		operatorSpecs: dateOperatorsSpecs,
		isDate: true,
		isDateOnly: true,
		hasDateExpression: true
	};

	endDateFieldFilterSpec: IFilterFieldSpec = {
		field: { label: 'DataList.Columns.EndDate', value: 'EndDate' },
		operatorSpecs: dateOperatorsSpecs,
		isDate: true,
		isDateOnly: true,
		hasDateExpression: true
	};

	workflowTypeTypeFieldFilterSpec: IFilterFieldSpec = {
		field: { label: 'Forms.CommonLabels.Type', value: 'WorkflowType' },
		operatorSpecs: [
			{ operator: IFilterOperator.Equals, values: this.typeValues, allowMultiple: true, translateValues: true },
			{ operator: IFilterOperator.NotEquals, values: this.typeValues, allowMultiple: true, translateValues: true }
		]
	};

	workflowStatusFieldFilterSpec: IFilterFieldSpec = {
		field: { label: 'DataList.Columns.Status', value: 'ExecutionStatus' },
		operatorSpecs: [
			{ operator: IFilterOperator.Equals, values: this.statusValues, allowMultiple: true, translateValues: true },
			{ operator: IFilterOperator.NotEquals, values: this.statusValues, allowMultiple: true, translateValues: true }
		]
	};

	spec$: Observable<IFilterSpec> = of({
		items: [
			this.workflowDefinitionNameFieldFilterSpec,
			this.workflowTypeTypeFieldFilterSpec,
			this.workflowStatusFieldFilterSpec,
			this.creationDateFieldFilterSpec,
			this.endDateFieldFilterSpec,
		]
	});
}
