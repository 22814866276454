
import { createAction } from '@ngrx/store';
import { CorrelationParams, newAggregationId } from 'apps/federation/src/app/root-store/models/correlated-actions.model';
import { IWorkflowSchedulingInstancesList, WorkflowSchedulingInstancesListSortProp, IWorkflowSchedulingInstancesListFilter, IWorkflowSchedulingConfig, IWorkflowSchedulingInstanceDetail } from '@aston/foundation';

import { createActionWithSingleProps, createEntityActions, createListActions } from '../functions';

const ActionPrefix = 'WorkflowScheduling';

export const instancesListActions = createListActions<IWorkflowSchedulingInstancesList, WorkflowSchedulingInstancesListSortProp, IWorkflowSchedulingInstancesListFilter>(ActionPrefix, 'Instances List');
export const {
	LoadListRequest: LoadInstancesListRequest,
	LoadListSuccess: LoadInstancesListSuccess,
	LoadListFailure: LoadInstancesListFailure,
	ListSettings: UpdateInstancesListSettings,
	AddListFilters: AddInstancesListFilters,
	RemoveListFilters: RemoveInstancesListFilters,
} = instancesListActions;

export const configActions = {
	...createEntityActions<IWorkflowSchedulingConfig>(ActionPrefix, 'Config'),
	ResetEntityRequest: createAction(`[${ActionPrefix}] Reset Config`),
	StartEntityRequest: createAction(`[${ActionPrefix}] Start Config`),
	DirectStartEntityRequest: createActionWithSingleProps<IWorkflowSchedulingConfig>(`[${ActionPrefix}] Direct Start Config`),
	RetryEntityOperationRequest: createAction(`[${ActionPrefix}] Retry Operation`,
		(instanceId: number, operationId: number, skipChildren: boolean, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( { instanceId, operationId, skipChildren, correlationParams } )),
	UpdateAndStartEntityRequest: createAction(`[${ActionPrefix}] Update and Start Config`,
		(entity: IWorkflowSchedulingConfig, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( { entity, correlationParams } )),

};

export const {
	LoadEntityRequest: LoadConfigRequest,
	LoadEntitySuccess: LoadConfigSuccess,
	LoadEntityFailure: LoadConfigFailure,
	UpdateEntityRequest: UpdateConfigRequest,
	UpdateEntitySuccess: UpdateConfigSuccess,
	UpdateEntityFailure: UpdateConfigFailure,
	ResetEntityRequest: ResetConfigRequest,
	StartEntityRequest: StartConfigRequest,
	DirectStartEntityRequest: DirectStartConfigRequest,
	RetryEntityOperationRequest: RetryOperationRequest,
	UpdateAndStartEntityRequest: UpdateAndStartConfigRequest
} = configActions;

export const instanceDetailActions = createEntityActions<IWorkflowSchedulingInstanceDetail>(ActionPrefix, 'Instance Detail');

export const {
	LoadEntityByIdRequest: LoadInstanceDetailRequest,
	LoadEntitySuccess: LoadInstanceDetailSuccess,
	LoadEntityFailure: LoadInstanceDetailFailure,
} = instanceDetailActions;
