import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as AppStoreSelectors from 'apps/federation/src/app/root-store/app-store/selectors';
import { AppStoreActions } from 'apps/federation/src/app/root-store/app-store';
import { getResolvedUrl } from '@aston/foundation';

@Injectable({
	providedIn: 'root'
})
export class RouteWithClearanceLevelsGuard implements CanActivate {

	constructor(private router: Router, private store: Store) {
	}

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
		if (!next.data.clearanceLevels || !next.data.clearanceLevels.length) {
			return of(true);
		}

		return this.store.select(AppStoreSelectors.selectClearsLevels(next.data.clearanceLevels)).pipe(
			map(allowed => {
				// TODO: Dispatch an action to show a permission denied modal here
				if (!allowed && next.data.redirectUrl) {
					// if the redirect url is also disallowed
					if (next.data.redirectUrl === getResolvedUrl(next)) {
						this.store.dispatch(AppStoreActions.NotFound({ message: 'Errors.NotFound' }))
					} else {
						this.router.navigate([next.data.redirectUrl]);
					}
				}
				return !!allowed;
			}),
			first()
		);
	}
}
