import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { AppLanguage, ActionType, UnsubscriberClass, DateFormatPipe, LanguagePipe, PageHeaderComponent, ISearchItem } from '@aston/foundation';
import { environment } from 'apps/federation/src/environments/environment';
import { IAction } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { AppStoreActions, AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsStoreSelectors } from 'apps/federation/src/app/root-store/notifications-store';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MultiSearchInputComponent } from '@aston/multi-search-input';
import { IUserAvatar } from '@aston/user-badge';

import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

import { TopBarSearchStore } from './top-bar.search.store';

@Component({
	selector: '[layout-top-bar]',
	templateUrl: './top-bar.component.html',
	standalone: true,
    imports: [
        AsyncPipe,
        UserAvatarComponent,
        DateFormatPipe,
        LanguagePipe,
        NgSelectModule,
        PageHeaderComponent,
        NgbTooltipModule,
        TranslateModule,
        UpperCasePipe,
        FormsModule,
        NgbDropdownModule,
        MultiSearchInputComponent,
    ],
	providers: [TopBarSearchStore]
})
export class TopBarComponent extends UnsubscriberClass {
	private searchStore = inject(TopBarSearchStore);

	@Input() currentLanguage: AppLanguage;
	@Input() user: IUserAvatar;
	@Input() tenantName: string;
	@Input() tenantLogo: string;
	@Input() tenantConsoleId: number;
	@Input() tenantLastUpdateDate: Date;

	@Output() selectLanguage: EventEmitter<AppLanguage> = new EventEmitter();
	@Output() logout: EventEmitter<void> = new EventEmitter();
	@Output() action: EventEmitter<IAction> = new EventEmitter();

	fr = AppLanguage.FR;
	en = AppLanguage.EN;
	hasTranslations = environment.translations.enabled;
	path = window.location.href;

	searchGroups = this.searchStore.results;
	searchResultsWithMore = this.searchStore.sortedResultsWithMore;

	operator$ = this.store.select(AppStoreSelectors.selectCurrentUser);
	unreadNotificationsCount$ = this.store.select(NotificationsStoreSelectors.selectUnreadCount);

	config$ = this.store.select(AppStoreSelectors.selectConfig);

	constructor(private store: Store) {
		super();
	}

	setLanguage(language: AppLanguage) {
		this.selectLanguage.emit(language);
	}

	userLogout() {
		this.logout.emit();
	}

	leavePlatform() {
		this.store.dispatch(AppStoreActions.NavigateToConsole({path: '/dashboard/subsidiaries'}))
	}

	editMyInformations() {
		this.store.dispatch(AppStoreActions.NavigateToGate({path: '/Account/edit?ReturnUrl=' + encodeURIComponent(this.path)}))
	}

	toggleNotificationsPanel() {
		this.action.emit({type: ActionType.TOGGLE, value: 'notifications'});
	}

	toggleThemesPanel() {
		this.action.emit({type: ActionType.TOGGLE, value: 'themes'});
	}

	search(term: string) {
		this.searchStore.search(term);
	}

	pickSearchResult(item: ISearchItem) {
		this.searchStore.select(item);
	}

	openHelp() {
		this.store.dispatch(AppStoreActions.NavigateToHelpPage())
	}

	updatePlatform() {
		//
	}
}
