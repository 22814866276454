
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import * as RoutesDefinitions from 'apps/federation/src/app/routes-definitions';
import { PageLoaderService } from '@aston/foundation';
import { PostLoginRouteService } from 'apps/federation/src/app/shared-module/services';
import { Store } from '@ngrx/store';

import { AuthenticationService } from '../services';
import { AppStoreSelectors } from '../../root-store/app-store';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

	constructor(
		private authenticationService: AuthenticationService,
		private pageLoaderService: PageLoaderService,
		private postLoginRouteService: PostLoginRouteService,
		private store: Store,
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.isAuthenticated(state.url);
	}

	canLoad(state: Route): Observable<boolean> | boolean {
		return this.isAuthenticated(state.path);
	}

	private isAuthenticated(redirectUrl: string): Observable<boolean> | boolean {
		this.pageLoaderService.start();

		// Wait for dependencies to be loaded before fireing the auth observable, for
		// Improvement 31982: Accès à la fiche client depuis la liste des actions
		return this.store.select(AppStoreSelectors.selectIsInitFinished).pipe(
			filter(finished => !!finished),
			delay(10), // give the auth lib a chance to proc first
			switchMap(_ => this.authenticationService.getIsAuthenticated()),
			// tap() doesn't seem to trigger the canActivate
			map((isAuthorized: boolean) => {
				this.pageLoaderService.end();

				if (isAuthorized) {
					return true;
				}

				this.postLoginRouteService.savePostLoginRoute(redirectUrl);
				this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
				return false;
			})
		);
	}
}
