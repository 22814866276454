import { AgeingBalanceRangeModel, IWorkflowSchedulingConfig, IWorkflowSchedulingConfigOperation, IWorkflowSchedulingInstanceDetail, IWorkflowSchedulingInstanceItem, WorkflowSchedulingExecutionStatus, WorkflowSchedulingOperationExecutionBehavior, WorkflowSchedulingOperationExecutionStatus, duration } from '@aston/foundation';

import { AgeingBalanceRangeModelApi, IWorkflowSchedulingInstanceDetailApi, IWorkflowSchedulingInstanceItemApi } from '../models/api';
import { IUserReportSetting } from '../models';
import { ReportTriggerType } from '../enums';


export function mapToWorkerSchedulingInstanceItem(apiModel: IWorkflowSchedulingInstanceItemApi): IWorkflowSchedulingInstanceItem {
	return {
		...apiModel
	};
}

export function mapToWorkerSchedulingInstanceDetail(apiModel: IWorkflowSchedulingInstanceDetailApi): IWorkflowSchedulingInstanceDetail {
	return {
		...mapToWorkerSchedulingInstanceItem(apiModel),
		...apiModel,
		tagsToProcess: apiModel.tagsToProcess || [],
		duration: duration(apiModel.creationDate, apiModel.endDate || new Date),
		relatedOperations: apiModel.relatedOperations.map(node => {
			const operation = {
				...node,
				originalName: node.name,
				name: `${(node.name && node.name.length < 40) ? node.name : (node.name.slice(0, 40) + '...')} - #${node.id}`,
				shortCommand: extractShortCommand(node.command),
				duration: duration(node.endDate, node.lastTryDate || new Date),
			};
			return operation;
		})
	};
}

export function extractShortCommand(command: string): string {
	const parts = command && command.match(/Definitions.Commands.([^,]*)/);
	return parts && parts[1] ? parts[1] : command;
}

export function getWorkflowSchedulingInstanceParentClass(childWorkflowItems: IWorkflowSchedulingInstanceItem[]) {
	return (childWorkflowItems && childWorkflowItems.length) ? 'has-workflow-children' : null;
}

export function getWorkflowSchedulingInstanceStatusClass(status: WorkflowSchedulingExecutionStatus|WorkflowSchedulingOperationExecutionStatus) {
	switch (status) {
		case WorkflowSchedulingExecutionStatus.WaitingToBeRun:
			return 'ready';
		case WorkflowSchedulingExecutionStatus.Success:
			return 'validated';
		case WorkflowSchedulingExecutionStatus.Failure:
		case WorkflowSchedulingExecutionStatus.Inconclusive:
			return 'refused';
		case WorkflowSchedulingExecutionStatus.Running:
			return 'pending';
		case WorkflowSchedulingOperationExecutionStatus.NotReached:
			return 'inactive';
		case WorkflowSchedulingOperationExecutionStatus.Skipped:
			return 'skipped';
		default:
			break;
	}
}

/**
 * Return a new graph with all duplicate node renamed
 */
export function renameWorkflowSchedulingDuplicates(config: IWorkflowSchedulingConfig): IWorkflowSchedulingConfig {
	const duplicates = duplicatesWorkflowSchedulingConfigs(config.root);
	return {
		...config,
		root: renameWorkflowSchedulingConfigDuplicates(config.root, duplicates)
	}
}

export function renameWorkflowSchedulingConfigDuplicates(root: IWorkflowSchedulingConfigOperation, duplicates: IWorkflowSchedulingConfigOperation[]): IWorkflowSchedulingConfigOperation {
	if (!root.children || root.children.length === 0) {
		return {
			...root,
			name: renameIfDuplicated(root, duplicates)
		};
	}
	const children = root.children.map(node => renameWorkflowSchedulingConfigDuplicates(node, duplicates));
	return {...root, children};
}

// return all duplicate node (same name)
export function duplicatesWorkflowSchedulingConfigs(root: IWorkflowSchedulingConfigOperation): IWorkflowSchedulingConfigOperation[] {
	const all = flatWorkflowSchedulingConfigs(root);
	return all.filter(node => all.find(other => other !== node && other.name === node.name));
}

// return all nodes as a flat array
export function flatWorkflowSchedulingConfigs(root: IWorkflowSchedulingConfigOperation): IWorkflowSchedulingConfigOperation[] {
	return (root.children || []).reduce((acc, child) => acc.concat((child.children || []) ? flatWorkflowSchedulingConfigs(child) : child), [])
										 .concat(root);
}

// utility function to return another name if it is a duplicate
export function renameIfDuplicated(node: IWorkflowSchedulingConfigOperation, duplicates: IWorkflowSchedulingConfigOperation[]): string {
	return duplicates.indexOf(node) === -1 ? node.name : node.name + ' ' + (duplicates.slice(duplicates.indexOf(node))
																												 .filter(other => other !== node && other.name === node.name).length + 1)
}

/**
 * Clean the raw graph object to remove all usefull flags isForkJoin, etc.
 */
export function cleanWorkflowCode(config: IWorkflowSchedulingConfig): IWorkflowSchedulingConfig {
	return {
		...config,
		root: cleanWorkflowNode(config.root)
	};
}

export function cleanWorkflowNode(root: IWorkflowSchedulingConfigOperation): IWorkflowSchedulingConfigOperation {
	const {childCount, isLeaf, isForkJoin, ...node} = root; // eslint-disable-line @typescript-eslint/no-unused-vars
	if (!root.children || root.children.length === 0) {
		return node;
	}
	const children = root.children.map(node => cleanWorkflowNode(node));
	return {...node, children};
}

/**
 * Rehydrate cleaned code from the editor to have all useful flags isForkJoin, etc.
 */
export function hydrateWorkflowCode(config: IWorkflowSchedulingConfig): IWorkflowSchedulingConfig {
	return {
		...config,
		root: hydrateWorkflowNode(config.root)
	};
}

export function hydrateWorkflowNode(root: IWorkflowSchedulingConfigOperation): IWorkflowSchedulingConfigOperation {
	const node = {
		...root,
		childCount: root.children ? root.children.length : 0,
		isLeaf: !(root.children && root.children.length),
		isForkJoin: root.executionBehavior === WorkflowSchedulingOperationExecutionBehavior.AfterAllOthersChildren,
	}
	if (!node.childCount) {
		return node;
	}
	const children = root.children.map(node => hydrateWorkflowNode(node));
	return {...node, children};
}

export function mapToAgeingBalanceRangeModel(apiModel: AgeingBalanceRangeModelApi): AgeingBalanceRangeModel {
	return {
		name: apiModel.name,
		min: apiModel.minRange,
		max: apiModel.maxRange
	};
}

export function mapToAgeingBalanceRangeApiModel(apiModel: AgeingBalanceRangeModel): AgeingBalanceRangeModelApi {
	return {
		name: apiModel.name,
		minRange: apiModel.min,
		maxRange: apiModel.max
	};
}


export function mapToUserReportSetting(model: IUserReportSetting): IUserReportSetting {
	return {
		...model,
		triggerType: model.triggerType || ReportTriggerType.None,
	}
}
