
import { AgeingBalanceRangeConfigModel, AgeingBalanceRangeModel, createEntityActions } from '@aston/foundation';
import { IUserReportSetting } from 'apps/federation/src/app/admin-module/models';

import { FeatureName } from './feature';

const ActionPrefix = FeatureName;

export const ageingBalanceRangesActions = createEntityActions<AgeingBalanceRangeModel[]>(ActionPrefix, 'AgeingBalance Ranges');
export const {
	LoadEntityRequest: LoadAgeingBalanceRangesRequest,
	LoadEntitySuccess: LoadAgeingBalanceRangesSuccess,
	LoadEntityFailure: LoadAgeingBalanceRangesFailure,
	UpdateEntityRequest: UpdateAgeingBalanceRangesRequest,
	UpdateEntitySuccess: UpdateAgeingBalanceRangesSuccess,
	UpdateEntityFailure: UpdateAgeingBalanceRangesFailure,
} = ageingBalanceRangesActions;

export const ageingBalanceRangesConfigActions = createEntityActions<AgeingBalanceRangeConfigModel>(ActionPrefix, 'AgeingBalance Ranges Config');
export const {
	LoadEntityRequest: LoadAgeingBalanceRangesConfigRequest,
	LoadEntitySuccess: LoadAgeingBalanceRangesConfigSuccess,
	LoadEntityFailure: LoadAgeingBalanceRangesConfigFailure,
} = ageingBalanceRangesConfigActions;

export const ageingBalanceRangesDefaultActions = createEntityActions<AgeingBalanceRangeModel[]>(ActionPrefix, 'AgeingBalance Ranges Default');
export const {
	LoadEntityRequest: LoadAgeingBalanceRangesDefaultRequest,
	LoadEntitySuccess: LoadAgeingBalanceRangesDefaultSuccess,
	LoadEntityFailure: LoadAgeingBalanceRangesDefaultFailure,
} = ageingBalanceRangesDefaultActions;

export const userReportsActions = createEntityActions<IUserReportSetting[]>(ActionPrefix, 'User Reports Settings');
export const {
	LoadEntityRequest: LoadUserReportsSettingsRequest,
	LoadEntitySuccess: LoadUserReportsSettingsSuccess,
	LoadEntityFailure: LoadUserReportsSettingsFailure,
	UpdateEntityRequest: UpdateUserReportsSettingsRequest,
	UpdateEntitySuccess: UpdateUserReportsSettingsSuccess,
	UpdateEntityFailure: UpdateUserReportsSettingsFailure,
} = userReportsActions;
