import { IWorkflowSchedulingInstancesList, WorkflowSchedulingInstancesListSortProp, IWorkflowSchedulingInstancesListFilter, IWorkflowSchedulingInstanceDetail, IWorkflowSchedulingConfig } from '@aston/foundation';

import { IStoreListStateSlice, createListSlice, IStoreEntityStateSlice, createEntitySlice } from '../functions';


export interface IWorkflowSchedulingState {
	instances: IStoreListStateSlice<IWorkflowSchedulingInstancesList, WorkflowSchedulingInstancesListSortProp, IWorkflowSchedulingInstancesListFilter>;
	instanceDetail: IStoreEntityStateSlice<IWorkflowSchedulingInstanceDetail>;
	config: IStoreEntityStateSlice<IWorkflowSchedulingConfig>;
}

export const initialState: IWorkflowSchedulingState = {
	instances: createListSlice({ prop: WorkflowSchedulingInstancesListSortProp.CreationDateTime, isDesc: true }),
	instanceDetail: createEntitySlice(),
	config: createEntitySlice()
};
