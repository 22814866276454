import { IStoreEntityStateSlice, createEntitySlice, AgeingBalanceRangeModel, AgeingBalanceRangeConfigModel } from '@aston/foundation';
import { IUserReportSetting } from 'apps/federation/src/app/admin-module/models';

export interface IAdminState {
	userReportSettings: IStoreEntityStateSlice<IUserReportSetting[]>,
	ageingBalanceRanges: IStoreEntityStateSlice<AgeingBalanceRangeModel[]>,
	ageingBalanceRangesDefault: IStoreEntityStateSlice<AgeingBalanceRangeModel[]>,
	ageingBalanceRangesConfig: IStoreEntityStateSlice<AgeingBalanceRangeConfigModel>,
}

export const initialState: IAdminState = {
	userReportSettings: createEntitySlice({ entity: [] }),
	ageingBalanceRanges: createEntitySlice(),
	ageingBalanceRangesDefault: createEntitySlice(),
	ageingBalanceRangesConfig: createEntitySlice(),
};
