import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';

import { createEntitySliceSelectors, createListSliceSelectors } from '../functions';

import { IWorkflowSchedulingState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, IWorkflowSchedulingState> = createFeatureSelector<IWorkflowSchedulingState>(FeatureName);

export const {
	selectSlice: selectInstancesSlice,
	selectList: selectInstancesList,
	selectError: selectInstancesListError,
	selectIsLoading: selectInstancesListIsLoading,
	selectPaging: selectInstancesListPaging,
	selectFilters: selectInstancesListFilters,
} = createListSliceSelectors(createSelector(selectState, state => state.instances));


export const {
	selectEntity: selectInstanceDetail,
	selectError: selectInstanceDetailError,
	selectIsLoading: selectInstanceDetailIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.instanceDetail));

export const {
	selectEntity: selectConfig,
	selectError: selectConfigError,
	selectIsLoading: selectConfigIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.config));
