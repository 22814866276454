import { createReducer, on } from '@ngrx/store';

import { withEntityReducer, withListReducer, entitySliceSuccessReducer, entitySliceFailureReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, IWorkflowSchedulingState } from './state';



export const workflowSchedulingReducer = createReducer<IWorkflowSchedulingState>(
	initialState,
	...withListReducer<IWorkflowSchedulingState>('instances', featureActions.instancesListActions),
	...withEntityReducer<IWorkflowSchedulingState>('config', featureActions.configActions),

	// ...withEntityReducer<IWorkflowSchedulingState>('instanceDetail', featureActions.instanceDetailActions),
	on(featureActions.LoadInstanceDetailSuccess, (state, action) => entitySliceSuccessReducer(state, 'instanceDetail', action)),
	on(featureActions.LoadInstanceDetailFailure, (state, action) => entitySliceFailureReducer(state, 'instanceDetail', action)),
	on(featureActions.LoadInstanceDetailRequest, (state, action) => {
		const newInstance = !state.instanceDetail.entity || action.id !== state.instanceDetail.entity.id;
		return  {
			...state,
			instanceDetail: {
				...state.instanceDetail,
				isLoading: !!newInstance,
				entity: newInstance ? null : state.instanceDetail.entity
			}
		}
	}),

	on(featureActions.ResetConfigRequest, state => ({
		...state,
		config: {
			// Force refresh from store
			...state.config,
			entity: {
				...state.config.entity
			}
		}
	})),
);
